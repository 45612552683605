import React, { useEffect, useState } from "react";
import GoBtn from "../components/gobtn";
import NavHead from "../components/navhead";
import axios from "axios";
import { useParams } from "react-router-dom";
import loader from "../../src/img/loader.gif";

const Blog = ({ url }) => {
  const { blogId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/api/webapp/articles`)
      .then((res) => {
        setIsLoading(false);
        setBlog(
          res.data.filter((item) => item.id === Number(blogId.slice(1)))[0]
        );
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [blogId]);
  return (
    <>
      {isLoading ? (
        <img src={loader} alt="loader" className="loader" />
      ) : (
        <div className="content_x__block">
          <NavHead />
          <div className="content_x">
            {blog ? (
              <>
                <h1 className="title pl-2 pr-2">{blog.header}</h1>
                <div className="meta mt-2 pl-2 pr-2">
                  {blog?.created_date?.slice(0, 10).split("-")[2] +
                    "." +
                    blog?.created_date?.slice(0, 10).split("-")[1] +
                    "." +
                    blog?.created_date?.slice(0, 10).split("-")[0]}
                </div>
                {blog.content?.map((item) =>
                  item.includes("backend/images") ? (
                    <div className="mt-2 pl-2 pr-2">
                      <img src={item} alt="image" style={{ width: "100%" }} />
                    </div>
                  ) : (
                    <div className="content mt-2 pl-2 pr-2">
                      <p dangerouslySetInnerHTML={{ __html: item }}></p>
                    </div>
                  )
                )}
              </>
            ) : null}
          </div>
          <div className="mt-2 pl-2 pr-2 back_btn_x">
            <GoBtn title="Вернуться на главную" />
          </div>
        </div>
      )}
    </>
  );
};

export default Blog;
