import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GoBtn from '../components/gobtn';
import NavHead from '../components/navhead';
import axios from 'axios';
import playIcon from '../img/play2.svg';
import loader from '../../src/img/loader.gif';
import ym from 'react-yandex-metrika';

const Media = ({ url }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [videos, setVideos] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/api/webapp/video`)
      .then((resp) => {
        setIsLoading(false);
        setVideos(resp.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    axios
      .get(`${url}/api/webapp/podcast`)
      .then((resp) => {
        setIsLoading(false);
        setPodcasts(resp.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <>
      {isLoading ? (
        <img src={loader} alt="loader" className="loader" />
      ) : (
        <div className="content_x__block">
          <NavHead title="Видео и подкасты" />

          <div className="content_x">
            <div className="mt-2">
              <div className="head-tl flex align-center justify-between mb-2 pr-2">
                <h3>Видео с мероприятий</h3>
                <Link
                  to="/media/videoList"
                  className="link"
                  onClick={() => {
                    ym('reachGoal', 'page_video');
                  }}
                >
                  Смотреть все
                </Link>
              </div>
            </div>
            <div className="posts-scroll flex align-stretch gap-2">
              {videos.map((item, index) => (
                <Link
                  to={`/media/videoList/:${item.id}`}
                  className="links"
                  key={index}
                >
                  <div className="media-scroll-item" key={item.header}>
                    <img
                      src={item.video_picture}
                      width="120"
                      alt="poster"
                      className="mt-2"
                    />
                    <img src={playIcon} alt="play-icon" className="play-icon" />
                    <div className="meta mt-2">
                      {item.header.length > 40
                        ? item.header.slice(0, 40) + '...'
                        : item.header}
                    </div>
                  </div>
                </Link>
              ))}
              <div className="posts-scroll-item posts-scroll-item-more flex flex-column justify-center">
                <Link
                  to="/media/videoList"
                  className="link flex flex-column justify-center align-center gap-1"
                  onClick={() => {
                    ym('reachGoal', 'page_video');
                  }}
                >
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="16"
                      viewBox="0 0 9 16"
                      fill="none"
                    >
                      <path
                        d="M1.78341 0.316624L8.77189 7.40897C8.85484 7.49341 8.91346 7.58487 8.94774 7.68338C8.98258 7.78188 9 7.88742 9 8C9 8.11258 8.98258 8.21812 8.94774 8.31662C8.91346 8.41513 8.85484 8.5066 8.77189 8.59103L1.78341 15.7045C1.58986 15.9015 1.34793 16 1.0576 16C0.767282 16 0.518434 15.8945 0.31106 15.6834C0.103687 15.4723 0 15.226 0 14.9446C0 14.6631 0.103687 14.4169 0.31106 14.2058L6.40783 8L0.31106 1.7942C0.117512 1.59719 0.0207376 1.35458 0.0207376 1.06639C0.0207376 0.777627 0.124424 0.527705 0.331798 0.316624C0.53917 0.105542 0.781106 9.53674e-07 1.0576 9.53674e-07C1.3341 9.53674e-07 1.57604 0.105542 1.78341 0.316624Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  Смотреть все
                </Link>
              </div>
            </div>
            <div className="mt-4">
              <div className="head-tl flex align-center justify-between mb-2 pr-2">
                <h3>Подкасты</h3>
                <Link
                  to="/media/podcastList"
                  className="link"
                  onClick={() => {
                    ym('reachGoal', 'page_podcast');
                  }}
                >
                  Смотреть все
                </Link>
              </div>
            </div>
            <div className="posts-scroll flex align-stretch gap-2">
              {podcasts.map((item, index) => (
                <Link
                  to={`/media/podcastList/:${item.id}`}
                  className="links"
                  key={index}
                >
                  <div className="media-scroll-item" key={item.header}>
                    <img
                      src={item.podcast_picture}
                      width="120"
                      alt="poster"
                      className="mt-2"
                    />
                    <img src={playIcon} alt="play-icon" className="play-icon" />
                    <div className="meta mt-2">
                      {item.header.length > 40
                        ? item.header.slice(0, 40) + '...'
                        : item.header}
                    </div>
                  </div>
                </Link>
              ))}
              <div className="posts-scroll-item posts-scroll-item-more flex flex-column justify-center">
                <Link
                  to="/media/podcastList"
                  className="link flex flex-column justify-center align-center gap-1"
                  onClick={() => {
                    ym('reachGoal', 'page_podcast');
                  }}
                >
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9"
                      height="16"
                      viewBox="0 0 9 16"
                      fill="none"
                    >
                      <path
                        d="M1.78341 0.316624L8.77189 7.40897C8.85484 7.49341 8.91346 7.58487 8.94774 7.68338C8.98258 7.78188 9 7.88742 9 8C9 8.11258 8.98258 8.21812 8.94774 8.31662C8.91346 8.41513 8.85484 8.5066 8.77189 8.59103L1.78341 15.7045C1.58986 15.9015 1.34793 16 1.0576 16C0.767282 16 0.518434 15.8945 0.31106 15.6834C0.103687 15.4723 0 15.226 0 14.9446C0 14.6631 0.103687 14.4169 0.31106 14.2058L6.40783 8L0.31106 1.7942C0.117512 1.59719 0.0207376 1.35458 0.0207376 1.06639C0.0207376 0.777627 0.124424 0.527705 0.331798 0.316624C0.53917 0.105542 0.781106 9.53674e-07 1.0576 9.53674e-07C1.3341 9.53674e-07 1.57604 0.105542 1.78341 0.316624Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  Смотреть все
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-2 pl-2 pr-2 back_btn_x">
            <GoBtn title="Вернуться на главную" />
          </div>
        </div>
      )}
    </>
  );
};

export default Media;
