import React from 'react';
import { useNavigate } from 'react-router-dom';

const PostDefault = ({ header, picture, id, date }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/blog/:${id}`);
      }}
    >
      <div className="topical-post-item">
        <div className="img">
          <img src={picture} alt="img" />
        </div>
        <div className="topical-post-item__info">
          <div className="meta">
            {date.slice(0, 10).split('-')[2] +
              '.' +
              date.slice(0, 10).split('-')[1] +
              '.' +
              date.slice(0, 10).split('-')[0]}
          </div>
          <div className="title">{header.slice(0, 50) + '...'}</div>
        </div>
      </div>
    </div>
  );
};

export default PostDefault;
