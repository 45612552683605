import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavHead from "../../components/navhead";
import GoBtn from "../../components/gobtn";
import axios from "axios";
import ControlsAudio from "../../components/controls";
import loader from "../../img/loader.gif";

const Podcast = ({ url }) => {
  const { podcastId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [podcast, setPodcast] = useState([]);
  const [maxId, setMaxId] = useState();
  const [minId, setMinId] = useState();
  useEffect(() => {
    axios
      .get(`${url}/api/webapp/podcast`, {
        timeout: 4000,
      })
      .then((resp) => {
        setIsLoading(false);
        setPodcast(
          resp.data.filter((item) => item.id === Number(podcastId.slice(1)))[0]
        );
        setMaxId(
          resp.data.reduce((acc, curr) => (acc.id > curr.id ? acc : curr), {
            id: -Infinity,
          }).id
        );
        setMinId(
          resp.data.reduce((acc, curr) => (acc.id < curr.id ? acc : curr), {
            id: Infinity,
          }).id
        );
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [podcastId]);
  const audio = new Audio(podcast.content);
  return (
    <>
      {isLoading ? (
        <img src={loader} alt="loader" className="loader" />
      ) : (
        <>
          <NavHead />
          <div className="player mt-2 pl-2 pr-2 ">
            <img
              className="poster"
              src={podcast.podcast_picture}
              alt="poster"
            />
            <h2>{podcast.header}</h2>
            <ControlsAudio audio={audio} maxId={maxId} minId={minId} />
            <p dangerouslySetInnerHTML={{ __html: podcast.description }}></p>
          </div>

          <div className="mt-2 mb-2 pl-2 pr-2">
            <GoBtn title="Вернуться на главную" />
          </div>
        </>
      )}
    </>
  );
};

export default Podcast;
