import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NavHead from '../../components/navhead';
import GoBtn from '../../components/gobtn';
import axios from 'axios';
import loader from '../../img/loader.gif';

const Video = ({ url }) => {
  const { videoId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [video, setVideo] = useState([]);
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/api/webapp/video`)
      .then((resp) => {
        setIsLoading(false);
        setVideo(
          resp.data.filter((item) => item.id === Number(videoId.slice(1)))[0]
        );
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [videoId]);
  return (
    <>
      {isLoading ? (
        <img src={loader} alt="loader" className="loader" />
      ) : (
        <>
          <NavHead title="Видео с мероприятий" />

          <div className="content mt-2 pl-2 pr-2">
            <h2>{video.header}</h2>
            {video.content_url ? (
              <div style={{ position: 'relative', display: 'block' }}>
                <div style={{ paddingTop: '56%' }}>
                  <iframe
                    src={`${video.content_url}`}
                    allowfullscreen=""
                    allow="encrypted-media"
                    style={{
                      position: 'absolute',
                      top: '0px',
                      right: '0px',
                      bottom: '0px',
                      left: '0px',
                      width: '99%',
                      height: '99%',
                    }}
                  ></iframe>
                </div>
              </div>
            ) : (
              <video
                src={video.content}
                poster={video.video_picture}
                controls
                className="video"
              ></video>
            )}
            <p dangerouslySetInnerHTML={{ __html: video.description }}></p>
          </div>
          <div className="mt-2 mb-2 pl-2 pr-2">
            <GoBtn title="Вернуться на главную" />
          </div>
        </>
      )}
    </>
  );
};

export default Video;
